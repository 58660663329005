<template>
	<div style="height: 100%;">
		<template v-if="theme.is_mobile && theme.width < 750">
			<div class="head">
				<div>{{params.page}} / {{Math.ceil(params.total/params.perPage)}}，共 {{params.total}} 条记录</div>
				<div @click="create_dialog = true">添加</div>
			</div>
			<div class="wrap">
				<div style="margin-bottom: 10px; padding: 10px; background-color: #fff; line-height: 1.5;" v-for="item in companies" :key="item.id" @click="onEdit(item)">
					<div>{{item.name}}</div>
					<div style="font-size: 12px; color: #666;" v-if="item.mobile"><i class="el-icon-mobile"></i> {{item.mobile}}</div>
					<div style="font-size: 12px; color: #666;" v-if="item.email"><i class="el-icon-message"></i> {{item.email}}</div>
					<div style="font-size: 12px; color: #666;" v-if="item.created_at">创建于 {{item.created_at}}</div>
				</div>
			</div>
			<van-pagination v-model="params.page" :total-items="params.total" :items-per-page="params.perPage" @change="(v) => { getContractsFees({ ...params, page: v }) }"></van-pagination>
		</template>
		<template v-else>
			<main-table>
				<el-form slot="header" :inline="true" :model="params" :size="theme.size">
					<el-form-item>
						<el-input v-model="params.keywords" placeholder="名称/手机号码/电子邮箱"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getCompanies({ ...params, page: 1})">查询</el-button>
						<el-button type="primary" @click="company = {}; create_dialog = true; " :disabled="!$utils.create('companies')">添加企业</el-button>
					</el-form-item>
				</el-form>
				<el-table slot="table" ref="companiesTable" class="scroll-wrapper absolute" height="100%" :data="companies" :size="theme.size">
					<el-table-column prop="at" label="名称" min-width="160">
						<template slot-scope="scope">
							<p>{{scope.row.name}}</p>
							<p>{{scope.row.code}}</p>
						</template>
					</el-table-column>
					<el-table-column prop="address" label="地址" min-width="260">
						<template slot-scope="scope">{{scope.row.address}}</template>
					</el-table-column>
					<el-table-column prop="at" label="开户" min-width="160">
						<template slot-scope="scope">
							<p>银行：{{scope.row.bank}}</p>
							<p>账户：{{scope.row.account}}</p>
						</template>
					</el-table-column>
					<el-table-column prop="at" label="联系" min-width="160">
						<template slot-scope="scope">
							<p>电话：{{scope.row.mobile}}</p>
							<p>邮箱：{{scope.row.email}}</p>
						</template>
					</el-table-column>
					<el-table-column prop="industry" label="行业" min-width="80"></el-table-column>
					<el-table-column prop="capital" label="资本" min-width="80" :formatter="(r) => { return r.capital+' 万'; }"></el-table-column>
					<el-table-column prop="at" label="操作时间" width="210" v-if="theme.width >= 1080">
						<template slot-scope="scope">
							<p>注册：{{scope.row.established_at}}</p>
							<p>更新：{{scope.row.updated_at}}</p>
							<p>创建：{{scope.row.created_at}}</p>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="更多操作" width="100">
						<template slot-scope="scope">
							<template v-if="!scope.row.deleted_at">
								<el-button type="text" @click="onEdit(scope.row)" :disabled="!$utils.update('companies')">编辑</el-button>
								<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('companies')">删除</el-button>
							</template>
							<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore('companies')" v-else>恢复</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					slot="footer"
					:layout="$config.PAGE_LAYOUT"
					:page-sizes="$config.PAHE_SIZES"
					:page-size="params.perPage"
					:current-page="params.page"
					:total="params.total"
					@size-change="(v) => { getCompanies({ ...params, page: 1, perPage: v }) }"
					@current-change="(v) => { getCompanies({ ...params, page: v }) }"
					background>
				</el-pagination>
			</main-table>
			<el-dialog width="580px" title="添加企业" :visible.sync="create_dialog" :fullscreen="theme.width < 580">
				<el-form ref="company_form" label-width="80px" :model="company" :rules="company_rules" :size="theme.size" status-icon>
					<el-form-item label="名称" prop="name">
						<el-input v-model="company.name" autocomplete="off" placeholder="请输入名称" clearable>
							<el-select slot="prepend" style="width: 100px;" v-if="!admin_user.shop_id" v-model="company.shop_id" placeholder="城市站点" filterable clearable>
								<el-option label="无" :value="0"></el-option>
								<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
							</el-select>
						</el-input>
					</el-form-item>
					<el-form-item label="唯一编码" prop="code">
						<el-input placeholder="请输入唯一编码" autocomplete="off" v-model="company.code" maxlength="18" show-word-limit clearable>
							<el-select slot="prepend" style="width: 160px;" v-model="company.type" placeholder="选择类型" @change="company.code = ''" clearable filterable>
								<el-option label="统一社会信用代码" :value="1"></el-option>
								<el-option label="组织机构代码" :value="2"></el-option>
								<el-option label="营业执照注册号" :value="3"></el-option>
							</el-select>
						</el-input>
					</el-form-item>
					<el-form-item label="详细地址" prop="address">
						<el-input style="width: 100%;" placeholder="请输入详细地址。不要重复填写地区" autocomplete="off" v-model="company.address" maxlength="68" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item label="手机号码" prop="mobile">
						<el-input v-model="company.mobile" autocomplete="off" placeholder="请输入企业手机号码" clearable></el-input>
					</el-form-item>
					<el-form-item label="电子邮箱" prop="email">
						<el-input v-model="company.email" autocomplete="off" placeholder="请输入企业电子邮箱" clearable></el-input>
					</el-form-item>
					<el-form-item label="开户银行" prop="bank">
						<el-input v-model="company.bank" autocomplete="off" placeholder="请输入企业开户银行" clearable></el-input>
					</el-form-item>
					<el-form-item label="开户账号" prop="account">
						<el-input v-model="company.account" autocomplete="off" placeholder="请输入企业开户账号" clearable></el-input>
					</el-form-item>
					<el-form-item label="注册资本" prop="capital">
						<el-input-number v-model="company.capital" :min="1" :max="1000000" label="注册资本"></el-input-number>
					</el-form-item>
					<el-form-item label="所属行业" prop="industry">
						<el-input v-model="company.industry" autocomplete="off" placeholder="请输入企业所属行业" clearable></el-input>
					</el-form-item>
					<el-form-item label="成立时间" prop="established_at">
						<el-date-picker v-model="company.established_at" type="date" placeholder="选择成立时间"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('company_form')">确 定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</template>
	</div>
</template>

<style>
	.mobile {
		display: flex; flex-direction: column; justify-content: space-between; height: 100%;
	}
	.mobile .head {
		display: flex; line-height: 45px; color: #666; font-size: 14px; border-bottom: 1px solid #ccc; padding: 0 10px; justify-content: space-between; background-color: #fff;
	}
	.mobile .wrap {
		flex: 1; background-color: #f5f5f5; overflow: auto;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			resetForm (ref) {
				this.create_dialog = false;
				this.$refs[ref].resetFields();
				this.company = {
					type: 1
				};
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					this.submitCompanies(this.company);
				});
			},
			onEdit (r) {
				this.company = { ...r }
				this.create_dialog = true;
			},
			onDelete (r) {
				if (r.brands_count) return this.$message.error('不能删除！【'+r.name+'】认证企业下还有'+r.brands_count+'个品牌。');
				if (r.shops_count) return this.$message.error('不能删除！【'+r.name+'】认证企业下还有'+r.shops_count+'间店铺。');
				this.$confirm('确定要删除【'+r.name+'】认证企业吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_COMPANIES, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getCompanies(this.params);
					this.$message.success(msg);
				});
			},
			onRestore (r) {
				this.$confirm('确定要删除【'+r.name+'】认证企业吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_COMPANIES, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getCompanies(this.params);
					this.$message.success(msg);
				});
			},
			async submitCompanies (data) {
				const res = await this.$http.post(this.$api.URI_COMPANIES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getCompanies(this.params);
						this.create_dialog = false;
					}
				});
			},
			async getCompanies (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_COMPANIES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.companies = result.data;
				this.shops = result.shops;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				};
			}
		},
		data() {
			var validEnterpriseCode = (rule, value, callback) => {
				if (!value) return callback(new Error('必须输入企业代码'));
				// if (this.company.type == 1 && this.company.code.length != 18) return callback(new Error('输入企业代码长度必须为18位'));
				// if (this.company.type == 2 && this.company.code.length != 9) return callback(new Error('输入企业代码长度必须为9位。例：xxxxxxxx-x'));
				// if (this.company.type == 3 && this.company.code.length != 15) return callback(new Error('输入企业代码长度必须为15位'));
				// if (this.company.type == 4 && this.company.code.length != 18) return callback(new Error('输入个人身份证长度必须为18位'));
				return callback();
			}
			return {
				shops: [],
				companies: [],
				create_dialog: false,
				company: {
					type: 1,
				},
				company_rules: {
					name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
					type: [{ required: true, message: '必须选择企业代码类型', trigger: 'blur' }],
					code: [{ required: true, message: '必须输入企业代码', trigger: 'blur' }, { validator: validEnterpriseCode, trigger: ['blur', 'change'] }],
					mobile: [{ required: true, message: '必须填写联系号码', trigger: 'blur' }],
					email: [{ required: true, message: '必须填写联系号码', trigger: 'blur' }],
					address: [{ required: true, message: '必须填写详细地址', trigger: 'blur' }]
				},
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getCompanies(this.params, true);
		}
	};
</script>